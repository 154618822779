import React from 'react';
// import { Helmet } from 'react-helmet';
// import { Helmet } from 'react-helmet-async';

import kontaktStyle from './kontakt.module.css';

import kamed from '../../assets/images/kamed450.jpg';
import medicr from '../../assets/images/medic-r450.png';
import danmed from '../../assets/images/danmed450.png';

const Kontakt = () => {
	return (
		<div className={kontaktStyle.contact}>
			{/* <Helmet>
				<title>Specjalista neurolog - Kostrzyn - Oborniki - Poznań - rejestracja wizyt</title>
				<meta name="description" content="Nested component" />
			</Helmet> */}
			<h1 className={kontaktStyle.h1}>Rejestracja wizyt</h1>
			<div className={kontaktStyle.description}>
				<div className={kontaktStyle.p}>
					Pacjentów przyjmuję w&nbsp;gabinetach w Obornikach Wielkopolskich oraz w&nbsp;Kostrzynie
					Wielkopolskim.
				</div>
			</div>
			{/* <div className={kontaktStyle.gabinet}>
				<img className={kontaktStyle.gabinetLogo} src={medicr} alt="gabinety medic-r" />
				<div className={kontaktStyle.gabinetDescription}>
					<h2>Gabinety Medic-R</h2>
					<div className={kontaktStyle.address}>
						Poznań<br /> ul. Poznańska 3
					</div>

					<a className={kontaktStyle.tel} href="tel:616632454">
						<span>Telefon do rejestracji:</span>
						<p>61 663 24 54</p>
					</a>
				</div>
			</div> */}

			<div className={kontaktStyle.gabinet}>
				<img className={kontaktStyle.gabinetLogo} src={danmed} alt="gabinety danmed" />
				<div className={kontaktStyle.gabinetDescription}>
					<h2>Gabinety Danmed</h2>
					<div>
						Oborniki Wielkopolskie<br /> ul. Szpitalna 2a
					</div>

					<a className={kontaktStyle.tel} href="tel:691833133">
						<span>Telefon do rejestracji:</span>
						<p>691 833 133</p>
					</a>

					<a className={kontaktStyle.tel} href="tel:691833122">
						<span>Telefon do rejestracji:</span>
						<p>691 833 122</p>
					</a>
				</div>
			</div>
			<div className={kontaktStyle.gabinet}>
				<img className={kontaktStyle.gabinetLogo} src={kamed} alt="gabinety kamed" />
				<div className={kontaktStyle.gabinetDescription}>
					<h2>Gabinety Ka-Med</h2>
					<div className={kontaktStyle.address}>
						Kostrzyn Wielkopolski
						<br />
						ul. Warszawska 4a/94
					</div>

					<a className={kontaktStyle.tel} href="tel:509333579">
						<span>Rejestracja wizyty:</span>
						<p>509 333 579</p>
					</a>
				</div>
			</div>

		</div>

	);
};

export default Kontakt;
